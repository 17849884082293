export default [
  {
    path: '',
    name: 'home',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue')
  },
  {
    path: 'invoices',
    component: () => import(/* webpackChunkName: "invoices" */ '@/views/invoices/Index.vue'),
    name: 'invoices.index',
    meta: {
      group: 'finances'
    }
  },
  {
    path: 'users',
    component: () => import(/* webpackChunkName: "invoices" */ '@/views/users/Index.vue'),
    name: 'users.index',
    meta: {
      group: 'membres'
    }
  },
  {
    path: 'invoices/:id',
    component: () => import(/* webpackChunkName: "invoices" */ '@/views/invoices/Details.vue'),
    name: 'invoices.details',
    meta: {
      group: 'finances'
    }
  },
  {
    path: 'faq-categories',
    component: () => import(/* webpackChunkName: "faq-categories" */ '@/views/faq-categories/Index.vue'),
    name: 'faq-categories.index',
    meta: {
      group: 'categories'
    }
  },
  {
    path: 'faq-categories/create',
    component: () => import(/* webpackChunkName: "faq-categories" */ '@/views/faq-categories/CreateEdit.vue'),
    name: 'faq-categories.create',
    meta: {
      group: 'categories'
    }
  },
  {
    path: 'faq-categories/edit/:id',
    component: () => import(/* webpackChunkName: "faq-categories" */ '@/views/faq-categories/CreateEdit.vue'),
    name: 'faq-categories.edit',
    meta: {
      group: 'categories'
    }
  },
  {
    path: 'product-categories',
    component: () => import(/* webpackChunkName: "faq-categories" */ '@/views/product-categories/Index.vue'),
    name: 'product-categories.index',
    meta: {
      group: 'categories'
    }
  },
  {
    path: 'product-categories/create',
    component: () => import(/* webpackChunkName: "faq-categories" */ '@/views/product-categories/CreateEdit.vue'),
    name: 'product-categories.create',
    meta: {
      group: 'categories'
    }
  },
  {
    path: 'product-categories/edit/:id',
    component: () => import(/* webpackChunkName: "faq-categories" */ '@/views/product-categories/CreateEdit.vue'),
    name: 'product-categories.edit',
    meta: {
      group: 'categories'
    }
  },
  {
    path: 'stores',
    component: () => import(/* webpackChunkName: "invoices" */ '@/views/stores/Index.vue'),
    name: 'stores.index',
    meta: {
      group: 'membres'
    }
  },
  {
    path: 'stores/:id/details',
    component: () => import(/* webpackChunkName: "support" */ '@/views/stores/Details.vue'),
    name: 'stores.details',
    meta: {
      group: 'membres'
    }
  },
  {
    path: 'orders',
    component: () => import(/* webpackChunkName: "orders" */ '@/views/orders/Index.vue'),
    name: 'orders.index',
    meta: {
      group: 'finances'
    }
  },
  {
    path: 'orders/:id',
    component: () => import(/* webpackChunkName: "orders" */ '@/views/orders/Details.vue'),
    name: 'orders.details',
    meta: {
      group: 'finances'
    }
  },
  {
    path: 'shipments',
    component: () => import(/* webpackChunkName: "shipments" */ '@/views/shipments/Index.vue'),
    name: 'shipments.index',
    meta: {
      group: 'finances'
    }
  },
  {
    path: 'shipments/:id',
    component: () => import(/* webpackChunkName: "shipments" */ '@/views/shipments/Details.vue'),
    name: 'shipments.details',
    meta: {
      group: 'finances'
    }
  },
  {
    path: 'support/requests',
    component: () => import(/* webpackChunkName: "support" */ '@/views/support-requests/Index.vue'),
    name: 'supportRequests.index',
    meta: {
      group: 'administration'
    }
  },
  {
    path: 'support/requests/:id',
    component: () => import(/* webpackChunkName: "support" */ '@/views/support-requests/Details.vue'),
    name: 'supportRequests.details',
    meta: {
      group: 'administration'
    }
  },
  {
    path: 'pages',
    component: () => import(/* webpackChunkName: "pages" */ '@/views/pages/Index.vue'),
    name: 'pages.index',
    meta: {
      group: 'administration'
    }
  },
  {
    path: 'pages/create',
    component: () => import(/* webpackChunkName: "pages" */ '@/views/pages/CreateEdit.vue'),
    name: 'pages.create',
    meta: {
      group: 'administration'
    }
  },
  {
    path: 'pages/edit/:id',
    component: () => import(/* webpackChunkName: "pages" */ '@/views/pages/CreateEdit.vue'),
    name: 'pages.edit',
    meta: {
      group: 'administration'
    }
  },
  {
    path: 'contacts/:id',
    component: () => import(/* webpackChunkName: "contacts" */ '@/views/contacts/Details.vue'),
    name: 'contacts.details',
    meta: {
      group: 'administration'
    }
  },
  {
    path: 'contacts',
    component: () => import(/* webpackChunkName: "contacts" */ '@/views/contacts/Index.vue'),
    name: 'contacts.index',
    meta: {
      group: 'administration'
    }
  },
  {
    path: 'products',
    component: () => import(/* webpackChunkName: "products" */ '@/views/products/Index.vue'),
    name: 'products.index',
    meta: {
      group: 'administration'
    }
  },
  {
    path: 'products/:id',
    component: () => import(/* webpackChunkName: "products" */ '@/views/products/Details.vue'),
    name: 'products.preview',
    meta: {
      group: 'administration'
    }
  },
  {
    path: 'connected-stores',
    component: () => import(/* webpackChunkName: "connectedStores" */ '@/views/connected-stores/Index.vue'),
    name: 'connectedStores.index',
    meta: {
      group: 'administration'
    }
  },
  {
    path: 'connected-stores/:provider/:id',
    component: () => import(/* webpackChunkName: "connectedStores" */ '@/views/connected-stores/Details.vue'),
    name: 'connectedStores.details',
    meta: {
      group: 'administration'
    }
  },
  {
    path: 'return-requests',
    component: () => import(/* webpackChunkName: "return-requests" */ '@/views/return-requests/Index.vue'),
    name: 'returns.index',
    meta: {
      group: 'finances'
    }
  },
  {
    path: 'return-requests/:id/store/:storeId',
    component: () => import(/* webpackChunkName: "return-requests" */ '@/views/return-requests/Details.vue'),
    name: 'returns.preview',
    meta: {
      group: 'finances'
    }
  }
]
