import { cloneDeep } from 'lodash-es'
import { createPaginatedStore } from '@/utils/store'
import http from '@/utils/http'
import { ProductCategory } from '@/types/productCategory'
import { Product } from '@/types/products'

const store = createPaginatedStore({}, {
  state: {
    textFilter: '',
    categoryFilter: '',
    statusFilter: '',
    storeFilter: '',
    categories: [],
    isSuspendedFilter: false,
    completedStore: false
  },

  mutations: {
    SET_TEXT_FILTER: (state: any, value: string) => {
      state.textFilter = value
    },

    SET_IS_SUSPENDED_FILTER: (state: any, value: boolean) => {
      state.isSuspendedFilter = value
    },

    SET_CATEGORY_FILTER: (state: any, value: string) => {
      state.categoryFilter = value
    },

    SET_STATUS_FILTER: (state: any, value: string) => {
      state.statusFilter = value
    },

    SET_STORE_FILTER: (state: any, value: string) => {
      state.storeFilter = value
    },

    SET_COMPLETED_STORE: (state: any, value: boolean) => {
      state.completedStore = value
    },

    SET_CATEGORIES: (state: any, value: ProductCategory[]) => {
      state.categories = value
    },

    UPDATE_PRODUCTS: (state: any, value: { products: string[]; categoryId: string | null; isSuspended?: boolean }) => {
      state.paginatedData.data = state.paginatedData.data.map((product: Product) => {
        if (value.products.find((e: string) => e === product.id)) {
          return {
            ...product,
            categoryId: value.categoryId || product.categoryId,
            isSuspended: value.isSuspended !== undefined ? value.isSuspended : product.isSuspended
          }
        }

        return product
      })
    }
  },

  actions: {
    fetch: async ({ commit, state }: any, data: { page: number | null }) => {
      commit('START_FETCHING')

      let params = {
        page: !data.page ? state.page : data.page,
        perPage: state.perPage
      }

      if (state.textFilter) {
        params = { ...params, text: state.textFilter } as any
      }

      if (state.categoryFilter) {
        params = { ...params, category: state.categoryFilter } as any
      }

      if (state.statusFilter) {
        params = { ...params, status: state.statusFilter } as any
      }

      if (state.storeFilter) {
        params = { ...params, store: state.storeFilter } as any
      }

      params = { ...params, isSuspended: state.isSuspendedFilter } as any

      if (state.completedStore) {
        params = { ...params, completedStore: state.completedStore } as any
      }

      commit('SET_PAGE', params.page)

      const { data: products } = await http.get('/v1/admin/products', {
        params
      })

      commit('FETCH_SUCCESS', products)

      return products
    },

    fetchCategories: async ({ commit }: any) => {
      const { data } = await http.get('/v1/product-categories/flat')

      commit('SET_CATEGORIES', data)

      return data
    },

    patchProducts: async ({ commit, state }: any, body: { products: string[]; categoryId: string | null; isSuspended: boolean | null }) => {
      commit('START_FETCHING')

      await http.patch('/v1/admin/products', body)

      commit('UPDATE_PRODUCTS', body)

      commit('FETCH_SUCCESS', state.paginatedData)
    }
  }
})

export default {
  ...store,
  state: () => {
    return cloneDeep(store.state)
  }
}
