import Vue from 'vue'
import { ActionContext } from 'vuex'
import { nanoid } from 'nanoid'
import { cloneDeep } from 'lodash-es'
import { ProductEditorState, ProductPhoto, ProductVariant, WithKey } from '@/store/types'
import http from '@/utils/http'

const getInitialState = (): ProductEditorState => ({
  isReady: false,
  productType: null,
  id: null,
  name: null,
  subtitle: null,
  shortDescription: null,
  description: null,
  categoryId: null,
  defaultPrice: null,
  defaultRegularPrice: null,
  slug: '',
  options: [],
  taxes: [],
  variants: [],
  store: null,
  initialVariants: [],
  productPhotos: [],
  initialPhotos: [],
  status: 'draft',
  isSuspended: false
})

const getVariant = (): ProductVariant => ({
  id: null,
  sku: null,
  inventoryMode: 'status',
  inventoryStatus: 'in_stock',
  inventoryQuantity: null,
  allowBackorders: null,
  lowStockThreshold: null,
  limitPerOrder: null,
  taxable: null,
  pricePolicy: 'default_price',
  price: null,
  regularPrice: null,
  finalSale: null,
  options: {},
  length: null,
  width: null,
  height: null,
  weight: null,
  productVariantPhotos: []
})

type LocalActionContext = ActionContext<ProductEditorState, any>

export default {
  namespaced: true,

  state: {
    ...getInitialState()
  },

  mutations: {
    SET_IS_READY: (state: ProductEditorState, isReady: boolean) => {
      state.isReady = isReady
    },

    SET_VALUES: (state: ProductEditorState, values: Partial<ProductEditorState>) => {
      for (const key in values) {
        // @ts-ignore
        Vue.set(state, key, values[key])
      }
    },

    SET_INIT_VARIANTS: (state: ProductEditorState, variants: Array<WithKey<ProductVariant>>) => {
      state.initialVariants = variants
    },

    SET_INIT_PHOTOS: (state: ProductEditorState, photos: Array<WithKey<ProductPhoto>>) => {
      state.initialPhotos = photos
    },

    PRODUCT_FETCHED: (state: ProductEditorState, product: Record<string, any>) => {
      state.id = product.id
      state.productType = product.type
      state.name = product.name
      state.subtitle = product.subtitle
      state.shortDescription = product.shortDescription
      state.description = product.description
      state.categoryId = product.categoryId
      state.defaultPrice = Number(product.defaultPrice)
      state.defaultRegularPrice = Number(product.defaultRegularPrice)
      state.variants = []
      state.options = product.options
      state.taxes = product.taxes
      state.productPhotos = product.productPhotos
      state.slug = product.slug
      state.store = product.store
      state.status = product.status
      state.isSuspended = product.isSuspended

      for (const variant of product.variants) {
        state.variants.push({
          ...getVariant(),
          key: nanoid(),
          id: variant.id,
          sku: variant.sku,
          inventoryMode: variant.inventoryMode,
          inventoryStatus: variant.inventoryStatus,
          inventoryQuantity: variant.inventoryQuantity,
          allowBackorders: variant.allowBackorders,
          lowStockThreshold: variant.lowStockThreshold,
          limitPerOrder: variant.limitPerOrder,
          taxable: variant.taxable,
          pricePolicy: variant.pricePolicy,
          price: variant.price,
          regularPrice: variant.regularPrice,
          finalSale: variant.finalSale,
          options: variant.options,
          length: variant.length,
          width: variant.width,
          height: variant.height,
          weight: variant.weight,
          productVariantPhotos: variant.productVariantPhotos
        })
      }
    },

    SET_IS_SUSPENDED: (state: ProductEditorState, isSuspended: boolean) => {
      state.isSuspended = isSuspended
    }
  },

  actions: {
    async initEditForm ({ commit }: LocalActionContext, id: string) {
      commit('SET_IS_READY', false)

      const { data: product } = await http.get(`/v1/products/${id}`)
      commit('PRODUCT_FETCHED', product)
      commit('SET_INIT_VARIANTS', cloneDeep(product.variants))
      commit('SET_INIT_PHOTOS', cloneDeep(product.productPhotos))

      commit('SET_IS_READY', true)
    },

    async fetchAndSetDefaultTaxes ({ rootState, dispatch }: LocalActionContext) {
      if (!rootState.taxRates.isFetched) {
        await dispatch('taxRates/fetch', rootState.auth.currentStoreId, { root: true })
      }

      await dispatch('setDefaultApplicableTaxes')
    },

    async setDefaultApplicableTaxes ({ rootGetters, commit }: LocalActionContext) {
      const availableTaxes = rootGetters['taxRates/getAvailableTaxes']
      commit('SET_VALUES', {
        taxes: availableTaxes.map((tax: Record<string, string>) => tax.code)
      })
    }
  }
}
