/* eslint-disable import/first */

/**
 * Initialization file to load additional resources needed by the application
 */

import Vue from 'vue'

// Modal plugin

import { ModalPlugin } from '@/modules/modal'
Vue.use(ModalPlugin)

// Forms plugin

import { FormsPlugin } from '@/modules/forms'
Vue.use(FormsPlugin)

// Zoom-image plugin
import { ZoomImagePlugin } from '@/modules/zoom-image'

// @ts-ignore (There is currently no supported types for this package)
import VCalendar from 'v-calendar'
Vue.use(VCalendar)

Vue.use(ZoomImagePlugin)

Vue.forms.registerTypes([
  {
    name: 'date-picker',
    input: () => import('@/components/forms/inputs/DatePicker.vue')
  },
  {
    name: 'multi-text',
    input: () => import('@/components/forms/inputs/MultiText.vue')
  },
  {
    name: 'category-type-selector',
    input: () => import('@/components/forms/inputs/CategoryTypeSelector.vue')
  },
  {
    name: 'editor',
    input: () => import('@/components/forms/inputs/Editor.vue')
  },
  {
    name: 'product-category-selector',
    input: () => import('@/components/forms/inputs/ProductCategorySelector.vue')
  },
  {
    name: 'page-editor',
    input: () => import('@/components/forms/inputs/PageEditor.vue')
  }
])

// Load Font Awesome

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('fa-icon', FontAwesomeIcon)

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faEllipsisH,
  faEllipsisV,
  faBars,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faPlus,
  faPen,
  faTimes,
  faInfoCircle,
  faTrash,
  faCog,
  faUpload,
  faCheck,
  faSync,
  faFilter,
  faCloudDownloadAlt,
  faFileInvoice,
  faFileInvoiceDollar,
  faExclamationTriangle,
  faCopy,
  faUsers,
  faUser,
  faFile,
  faClock,
  faCubes,
  faUserTag,
  faQuestion,
  faTag,
  faReceipt,
  faHeadset,
  faEye,
  faSearch,
  faStore,
  faExclamation
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faExclamation,
  faSearch,
  faEllipsisH,
  faEllipsisV,
  faBars,
  faChevronLeft,
  faExclamationTriangle,
  faCopy,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faPlus,
  faPen,
  faTimes,
  faInfoCircle,
  faTrash,
  faCog,
  faUpload,
  faCheck,
  faSync,
  faFilter,
  faFile,
  faClock,
  faQuestion,
  faEye,
  faTag,
  faCloudDownloadAlt,
  faFileInvoice,
  faCubes,
  faFileInvoiceDollar,
  faHeadset,
  faUsers,
  faReceipt,
  faUserTag,
  faUser,
  faStore
)

// Bootstrap Vue

import { SpinnerPlugin, AvatarPlugin, FormSelectPlugin, FormCheckboxPlugin } from 'bootstrap-vue'
// Vue.use(ModalPlugin)
Vue.use(SpinnerPlugin)
Vue.use(AvatarPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormSelectPlugin)

// Click Outside Directive

// @ts-ignore
import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside)

import Toasted from 'vue-toasted'
Vue.use(Toasted, {
  duration: 3000,
  position: 'top-center'
})

// Handle user JWT authentication

/*
import store from './store'
import { isAuthenticated } from '@/utils/auth-utils'

if (isAuthenticated()) {
  store.commit('USER_AUTHENTICATED')
  store.commit('SET_CURRENT_ACCOUNT_ID', localStorage.getItem('workspace_id'))
}
*/

import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Filler,
  Legend,
  Title,
  Tooltip
} from 'chart.js'

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Filler,
  Legend,
  Title,
  Tooltip
)
